<template>
    <div id="courseIntroductionDetails">
        <div class="box">
            <div class="breadCrumbs">
                <span style="color: #999999;cursor: pointer;" @click="indexClick">首页</span>>
                <span style="color: #999999;cursor: pointer;" @click="courseClick">课程实训</span>>
                <span>智慧工地系统</span>
            </div>
        <div v-for="(item,index) in TrainingList" :key="index" :style="index==currentSort ? '' : 'display:none;'" style="width: 60%;height: 800px; float: left;background-color: #fff;overflow-y: auto;position: relative;">
            <p style="text-align:center;padding-top: 10px;font-weight: 500;font-size:18px;color: #76C8F6;;">第{{item.isOrder+1}}节  {{item.knowledgeName}}</p>
            <p style="font-weight: 500;color: #333333;padding-left: 20px;padding-top: 20px;">实训任务（实训目标+实训知识点）</p>
            <p style="font-weight: 400;font-size: 12px;padding-left: 20px;padding-top: 10px;">{{ item.trainingTask }}</p>
            <p style="font-weight: 500;color: #333333;padding-left: 20px;padding-top: 40px;">实训实训步骤（结合任务，系统操作流程）</p>
            <p style="font-weight: 400;font-size: 12px;padding-left: 20px;padding-top: 10px;">{{ item.trainingProcess }}</p>
            <p style="font-weight: 500;color: #333333;padding-left: 20px;padding-top: 40px;">实训价值（可以解决什么问题）</p>
            <p style="font-weight: 400;font-size: 12px;padding-left: 20px;padding-top: 10px;">{{ item.trainingValue }}</p>
            <div style="position:absolute; bottom:3%;left: 40%;">
                <el-button type="primary" round style="background-color: #76C8F6;border: none;margin-left: 10px;" @click="examineClick(item.id,index+=1)">考核</el-button>
                <el-button type="primary" round style="background-color: #76C8F6;border: none;" @click="courseIntroductionDetails(item.digitalTrainingId,item.id)">去实训</el-button>
            </div>
        </div>
        <div class="rightBox">
            <div style="width: 100%;height: 50px;line-height: 50px;text-align: center;border-bottom: 1px solid #F7F8FA;font-weight: bold;color: #333333;">
                实训目录
            </div>
            <ul style="margin: 15px;">
                <li v-for="(item,index) in TrainingList" :key="index" @click="handTraining(index)" style="width: 100%;height: 40px;background-color: #F7F8FA;line-height: 40px;margin-bottom: 10px;">
                    <img :src="require('@/assets/img/courseTechingimg/minibook.png')" alt="" srcset="" style="vertical-align: middle;margin-left: 10px;">
                    <span style="font-weight: 800;padding-left: 20px;">第{{item.isOrder+1}}节</span>
                    <span style="padding-left: 20px;font-size: 14px;">{{item.knowledgeName}}</span>
                </li>
            </ul>
        </div>
        </div>
        
    </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted, computed,watch ,} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getStorage, setStorage } from "@/js/common";
import {backUrl} from "@/js/util";
import {F_Insert_browsingHistory,F_Insert_trainingRecord,f_go_courseTraining,get_digitalTrainingDataInfo} from "@/js/homeListApi.js"
import {baseUrl} from '@/js/util.js'
import { ElMessage } from 'element-plus';
export default {
    setup () {
        const router = useRouter();
        const store = useStore();
        const state = reactive({
            baseUrl: baseUrl,
            userId: getStorage('userId'),
            TrainingList: [],
            currentSort: 0,
            Id:getStorage('digitalTrainingId')

        })
        onMounted(()=>{
            methods.rowClick();
            methods.browsingHistory();
        })
        const methods = {
            rowClick() {
                let datas = {
                    digitalTrainingId:state.Id,
                }
                get_digitalTrainingDataInfo(datas).then((res)=>{
                    if (res.data.code == 1) {
                        state.TrainingList = res.data.data
                        console.log(state.TrainingList);
                    }else{
                        ElMessage.error(res.data.message)
                    }
                })
            },
            //进入此页面增加一次浏览量
            browsingHistory() {
                let datas = {
                    courseId: state.Id,
                    allCount: 0,
                    monthCount: 1,
                }
                F_Insert_browsingHistory(datas).then((res) => {
                    if (res.data.code == 1) {
                        console.log('添加该课程浏览量成功');
                    }
                })
            },
            //点击切换左侧知识点
            handTraining(NameIndex){
                state.currentSort = NameIndex;
            },
            //点击去实训添加实训次数
            courseIntroductionDetails(digitalTrainingId,Id){
                let datas = {
                    trainingId: digitalTrainingId,
                    count: 1,
                }
                F_Insert_trainingRecord(datas).then((res) => {
                    if (res.data.code == 1) {
                        console.log('添加实训次数成功');
                         // 跳转实训页面
                        window.open('http://whpugd.hiaipc.com/#/')
                    }else{
                        ElMessage.error(res.data.message)
                    }
                })
               

            },
            //点击考核
            examineClick(Id,jie){
                let datas = {
                    dtKnowledgePointId:Id,
                }
                f_go_courseTraining(datas).then((res) => {
                    if (res.data.code == 1) {
                        setStorage('assessment',res.data.data)
                        setStorage('jie',jie)
                        console.log(res.data.data,'点击考核');
                        router.push('/home/examine')
                    }else{
                        console.log(res.data.data,'点击考核');
                        ElMessage.error(res.data.message)
                    }
                })
            },



            indexClick() {
                store.commit("SetnarbarMenuActive", 1);
                setStorage("narbarMenuActive", 1);
                router.push('/home/index');
            },
            courseClick() {
                router.push('/home/digitalTraining');
            },
            
        }
        return{
            ...toRefs(state),
            ...methods,
        }
    }
}
</script>
<style  lang="scss" scoped>
#courseIntroductionDetails{
    height: 100%;
    position: relative;
    background: #F7F8FA;
    .box{
        width: 66.5%;
        height: 100%;
        margin: 0 auto;
    }
    .breadCrumbs{
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 12px;
    }
    .rightBox{
        width: 39%;
        height:800px;
        background: #fff;
        float: right;
        overflow-y: auto;
    }
    
   :deep .el-collapse-item__header{
        background: #F7F8FA;
    }
    :deep .el-collapse-item__wrap{
        border: none;
    }
}
</style>